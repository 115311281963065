@font-face {
    font-family: 'BasierCircle';
    src: url('basiercircle-bold-webfont.eot');
    src: url('basiercircle-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('basiercircle-bold-webfont.woff2') format('woff2'),
         url('basiercircle-bold-webfont.woff') format('woff'),
         url('basiercircle-bold-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'BasierCircle';
    src: url('basiercircle-bolditalic-webfont.eot');
    src: url('basiercircle-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('basiercircle-bolditalic-webfont.woff2') format('woff2'),
         url('basiercircle-bolditalic-webfont.woff') format('woff'),
         url('basiercircle-bolditalic-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;

}

@font-face {
    font-family: 'BasierCircle';
    src: url('basiercircle-medium-webfont.eot');
    src: url('basiercircle-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('basiercircle-medium-webfont.woff2') format('woff2'),
         url('basiercircle-medium-webfont.woff') format('woff'),
         url('basiercircle-medium-webfont.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;

}

@font-face {
    font-family: 'BasierCircle';
    src: url('basiercircle-mediumitalic-webfont.eot');
    src: url('basiercircle-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('basiercircle-mediumitalic-webfont.woff2') format('woff2'),
         url('basiercircle-mediumitalic-webfont.woff') format('woff'),
         url('basiercircle-mediumitalic-webfont.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;

}

@font-face {
    font-family: 'BasierCircle';
    src: url('basiercircle-regular-webfont.eot');
    src: url('basiercircle-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('basiercircle-regular-webfont.woff2') format('woff2'),
         url('basiercircle-regular-webfont.woff') format('woff'),
         url('basiercircle-regular-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'BasierCircle';
    src: url('basiercircle-regularitalic-webfont.eot');
    src: url('basiercircle-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('basiercircle-regularitalic-webfont.woff2') format('woff2'),
         url('basiercircle-regularitalic-webfont.woff') format('woff'),
         url('basiercircle-regularitalic-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;

}

@font-face {
    font-family: 'BasierCircle';
    src: url('basiercircle-semibold-webfont.eot');
    src: url('basiercircle-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('basiercircle-semibold-webfont.woff2') format('woff2'),
         url('basiercircle-semibold-webfont.woff') format('woff'),
         url('basiercircle-semibold-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'BasierCircle';
    src: url('basiercircle-semibolditalic-webfont.eot');
    src: url('basiercircle-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('basiercircle-semibolditalic-webfont.woff2') format('woff2'),
         url('basiercircle-semibolditalic-webfont.woff') format('woff'),
         url('basiercircle-semibolditalic-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;

}
