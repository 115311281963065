.md-text-block {
  margin-bottom: 150px;
}

.md-text-block h1 {
  font-family: Silka;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;

  letter-spacing: -0.01em;

  color: #333333;
}

.md-text-block h2 {
  margin-top: 56px;

  font-family: Silka;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: -0.02em;

  color: #333333;
}

.md-text-block h3 {
  font-family: Silka;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: -0.02em;

  color: #999999;
}

.md-text-block p {
  font-family: Basier Square;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;

  letter-spacing: -0.02em;

  color: #333333;
}