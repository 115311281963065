@font-face {
    font-family: 'Silka';
    src: url('silka-black-webfont.eot');
    src: url('silka-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('silka-black-webfont.woff2') format('woff2'),
         url('silka-black-webfont.woff') format('woff'),
         url('silka-black-webfont.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;

}




@font-face {
    font-family: 'Silka';
    src: url('silka-bold-webfont.eot');
    src: url('silka-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('silka-bold-webfont.woff2') format('woff2'),
         url('silka-bold-webfont.woff') format('woff'),
         url('silka-bold-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;

}




@font-face {
    font-family: 'Silka';
    src: url('silka-semibold-webfont.eot');
    src: url('silka-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('silka-semibold-webfont.woff2') format('woff2'),
         url('silka-semibold-webfont.woff') format('woff'),
         url('silka-semibold-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;

}





@font-face {
    font-family: 'Silka';
    src: url('silka-medium-webfont.eot');
    src: url('silka-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('silka-medium-webfont.woff2') format('woff2'),
         url('silka-medium-webfont.woff') format('woff'),
         url('silka-medium-webfont.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;

}



@font-face {
    font-family: 'Silka';
    src: url('silka-regular-webfont.eot');
    src: url('silka-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('silka-regular-webfont.woff2') format('woff2'),
         url('silka-regular-webfont.woff') format('woff'),
         url('silka-regular-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;

}




@font-face {
    font-family: 'Silka';
    src: url('silka-light-webfont.eot');
    src: url('silka-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('silka-light-webfont.woff2') format('woff2'),
         url('silka-light-webfont.woff') format('woff'),
         url('silka-light-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;

}



@font-face {
    font-family: 'Silka';
    src: url('silka-extralight-webfont.eot');
    src: url('silka-extralight-webfont.eot?#iefix') format('embedded-opentype'),
         url('silka-extralight-webfont.woff2') format('woff2'),
         url('silka-extralight-webfont.woff') format('woff'),
         url('silka-extralight-webfont.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;

}


@font-face {
    font-family: 'Silka';
    src: url('silka-thin-webfont.eot');
    src: url('silka-thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('silka-thin-webfont.woff2') format('woff2'),
         url('silka-thin-webfont.woff') format('woff'),
         url('silka-thin-webfont.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;

}

